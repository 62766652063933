import { IRoute } from 'hooks/useVendorsRoutes/useVendorsRoutes.d';
import { PathMatch, matchPath } from 'react-router-dom';

export function routesMatch(routes: IRoute[], pathname: string) {
  let matchResult: PathMatch;

  const firstMatch = routes.find((route) => {
    matchResult = matchPath(
      {
        path: `${route.path}`,
        end: true,
        caseSensitive: false
      },
      pathname
    );
    return matchResult;
  });

  return firstMatch;
}
