import { styled } from '@hexa-ui/theme';

const Container = styled('div', {
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis'
});

const Styled = {
  Container
};

export default Styled;
