import { styled } from '@hexa-ui/theme';

const Container = styled('div', {
  display: 'flex',
  flexWrap: 'wrap',
  gap: '18px'
});

const Styled = {
  Container
};

export default Styled;
