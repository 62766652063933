import { Image, Radio } from '@hexa-ui/components';
import ConditionalTooltip from 'components/ConditionalTooltip/ConditionalTooltip';
import { useIntl } from 'react-intl';
import { CountryCardProps } from './CountryCard.d';
import Styled from './CountryCard.styles';

const CountryCard = ({
  urlImage,
  onClick,
  name,
  isDisabled,
  isChecked,
  isFullWidth,
  hasOutlineBorder
}: CountryCardProps) => {
  const { formatMessage } = useIntl();

  return (
    <Styled.CustomCard
      data-testid="country-card"
      onClick={onClick}
      type={isDisabled ? 'countryCreated' : 'countryNotCreated'}
      isChecked={isChecked}
      isFullWidth={isFullWidth}
      hasOutlineBorder={hasOutlineBorder}
    >
      <Styled.ContentCard>
        <Image height="48px" width="48px" alt={`${name}-flag`} src={urlImage} />

        <ConditionalTooltip
          showTooltip={isDisabled}
          text={formatMessage({ id: 'PrimaryCluster.Tooltip' })}
        >
          <Styled.RadioButton>
            <Radio.Item
              data-testid="country-card-radio"
              value={name}
              checked={isChecked}
              id={name}
              label={name}
              disabled={isDisabled}
            />
          </Styled.RadioButton>
        </ConditionalTooltip>
      </Styled.ContentCard>
    </Styled.CustomCard>
  );
};

export default CountryCard;
