import { Error403, Error404 } from '@hexa-ui/components';
import { styled } from '@hexa-ui/theme';

export const ErrorContainer = styled('div', {
  inset: 0,
  width: '100%',
  height: '100%',
  position: 'absolute',
  backgroundColor: '$neutral10'
});

export const Error404Styled = styled(Error404, {
  position: 'absolute',
  top: '50%',
  left: '50%'
});

export const Error403Styled = styled(Error403, {
  position: 'absolute',
  top: '50%',
  left: '50%'
});

const Styled = {
  ErrorContainer,
  Error404Styled,
  Error403Styled
};

export default Styled;
