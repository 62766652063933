import { createContext, useCallback, useMemo, useReducer } from 'react';
import {
  IGlobalGroupContext,
  IGlobalGroupContextData,
  UpdateActions
} from './GlobalGroupContext.d';

const GlobalGroupContext = createContext({} as IGlobalGroupContext);

function reducer(state: any, action: UpdateActions): IGlobalGroupContextData {
  return { ...state, ...action.payload };
}

const EMPTY_DATA = {
  enabled: false,
  id: '',
  name: '',
  childGroupIds: [],
  childGroups: []
};

export const initialState: IGlobalGroupContextData = {
  data: EMPTY_DATA
};

function GlobalGroupProvider({ children }: { children: React.ReactNode }) {
  const [state, dispatch] = useReducer(reducer, initialState);

  const onUpdateData = useCallback((data) => {
    dispatch({ payload: { data } });
  }, []);

  const availableVendors = useMemo(() => {
    const { data } = state;
    const allVendors = data?.vendors;

    const assignedVendors = data?.childGroups?.flatMap((childGroup) => childGroup.vendors);

    return allVendors?.filter(
      (vendor) => !assignedVendors.some((vendorAssigned) => vendorAssigned.id === vendor.id)
    );
  }, [state]);

  const contextValue: IGlobalGroupContext = useMemo(() => {
    return {
      data: state.data,
      availableVendors,
      updateData: onUpdateData
    };
  }, [state.data, availableVendors, onUpdateData]);

  return <GlobalGroupContext.Provider value={contextValue}>{children}</GlobalGroupContext.Provider>;
}

export { GlobalGroupContext, GlobalGroupProvider };
