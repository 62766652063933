import { Group, GroupMinimal } from 'domain/Group';
import { useEffect, useState } from 'react';
import { getAllGroups, getAllRoots } from '../group-service';

function useGetAllGroups(onlyRoots = false, enable = true) {
  const [allGroups, setAllGroups] = useState<GroupMinimal[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  const getAllSubGroups = (groups: GroupMinimal[]) => {
    let subGroups = [];
    groups?.forEach((group) => {
      subGroups = subGroups.concat(group.childGroups);
    });
    return subGroups;
  };

  const fetchGroups = async () => {
    try {
      let response: { content: Group[] };
      if (onlyRoots) {
        response = await getAllRoots();
      } else {
        response = await getAllGroups();
      }
      const data: GroupMinimal[] = response.content.map((el) => {
        const subGroups = getAllSubGroups(el.childGroups);
        return {
          ...el,
          subGroups
        };
      });
      setAllGroups(data);
    } catch (err) {
      setError(err.message);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (enable) {
      fetchGroups();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return { allGroups, isLoading, error };
}

export default useGetAllGroups;
