import { Button } from '@hexa-ui/components';
import { ButtonsActionsProps } from './ButtonsActions.d';
import Styled from './ButtonsActions.styles';

const ButtonsActions = ({
  onConfirm,
  onCancel,
  cancelIsDisabled = false,
  confirmIsDisabled = false,
  cancelIsLoading = false,
  confirmIsLoading = false,
  confirmText = 'Next',
  cancelText = 'Go back'
}: ButtonsActionsProps) => {
  return (
    <Styled.ActionContainer>
      <Button
        onClick={onCancel}
        disabled={cancelIsDisabled}
        variant="secondary"
        isLoading={cancelIsLoading}
      >
        {cancelText}
      </Button>
      <Button
        onClick={onConfirm}
        disabled={confirmIsDisabled}
        variant="primary"
        isLoading={confirmIsLoading}
      >
        {confirmText}
      </Button>
    </Styled.ActionContainer>
  );
};

export default ButtonsActions;
