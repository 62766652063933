import { Group } from 'domain/Group';

export enum Cluster {
  EU = 'EU',
  GLOBAL = 'GLOBAL'
}

export interface Country {
  code: string;
  name: string;
  cluster: Cluster;
  icon: string;
  isDisabled?: boolean;
}

export interface UseCountryClusterReturn {
  getAllCountriesByCluster: (cluster: Cluster, groups: Group[]) => Country[];
  getClusterByCountryCode: (countryCode: string) => Cluster | null;
  getCountriesByUserCountry: (userCountryCode: string, groups: Group[]) => Country[];
  getCountryByCode: (userCountryCode: string) => Country;
  isLoading: boolean;
  countries?: Country[];
}
