import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

export function useHeaderHeight() {
  const [headerHeight, setHeaderHeight] = useState('0');
  const location = useLocation();

  useEffect(() => {
    const header = document.querySelector('#mfe-content-header');
    if (header) {
      setTimeout(() => {
        setHeaderHeight(`${(header as HTMLElement).offsetHeight}px`);
      }, 0);
    } else setHeaderHeight('0');
  }, [location.pathname]);

  return { headerHeight };
}
