import { useAuthenticationService } from 'admin-portal-shared-services';
import { getPermissionGroup, permissionsGroups } from './normalizePermissionsScopes';

type UsePermissions = {
  hasPermission: (permission: keyof typeof permissionsGroups) => boolean;
};

export const usePermissions = (): UsePermissions => {
  const scopes = useAuthenticationService()
    .getUserScopes()
    .map((s) => s.toLowerCase());

  const hasPermission = (permission: keyof typeof permissionsGroups): boolean => {
    const permissionGroup = getPermissionGroup(permission);

    if (!permissionGroup.length) {
      return false;
    }

    const hasPermissionValue = permissionGroup.some((p) => scopes.includes(p.toLowerCase()));

    return hasPermissionValue;
  };

  return { hasPermission };
};
