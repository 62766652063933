import MessageMap from '../i18n.d';

const enUS: MessageMap = {
  test: {
    translate: 'Translate',
    missing: 'Only English option available'
  },
  HomePage: {
    Header: {
      SelectPrimaryCluster: 'Select a primary cluster',
      HeaderTooltip: 'A primary cluster corresponds to the Country of operation.',
      Subtitle: 'Select to access sellers list and subgroups.',
      CreateClusterButton: 'Create new primary cluster'
    }
  },
  Sidebar: {
    Home: 'Home'
  },
  Header: {
    MainTitle: 'Vendors management',
    SellersManagement: 'Sellers Management',
    NewPrimaryCluster: 'New Primary Cluster',
    CreateGroup: 'Create group',
    CreateSubgroup: 'Create subgroup',
    RootDetails: 'Country details',
    GroupDetails: 'Group details',
    SubgroupDetails: 'Subgroup details',
    PrimaryCluster: 'Edit primary cluster',
    EditGroup: 'Edit group',
    EditSubgroup: 'Edit subgroup'
  },
  EmptyMessage: {
    Title: 'Create first cluster',
    Subtitle: 'A primary cluster correponds to the Country of operation.',
    Action: 'Create primary cluster',
    LayoutCountry: {
      NoVendors: 'No sellers available',
      NoVendorsDescription:
        'All sellers are assigned to a group. Wait for an update or remove sellers from groups to reallocate them.',
      ReturnButton: 'Return to groups'
    },
    LayoutGroup: {
      NoVendors: 'No sellers available',
      NoVendorsDescription:
        'All sellers are assigned to a subgroup. Wait for an update or remove sellers from subgroups to reallocate them.',
      ReturnButton: 'Return to subgroups'
    }
  },
  CardCountry: {
    Vendors: '{vendors, plural, one {{vendors} seller} other {{vendors} sellers}}',
    Sellers: '{vendors, plural, one {{vendors} seller} other {{vendors} sellers}}',
    Groups: '{groups, plural, one {{groups} seller group} other {{groups} groups}}',
    Subgroups: 'subgroups',
    LayoutCountry: {
      Groups: '{groups, plural, one {{groups} seller group} other {{groups} groups}}',
      Vendors: '{vendors, plural, one {{vendors} seller} other {{vendors} sellers}}'
    },
    LayoutGroup: {
      Groups: '{groups, plural, one {{groups} seller subgroup} other {{groups} seller subgroups}}',
      Vendors: '{vendors, plural, one {{vendors} seller} other {{vendors} sellers}}'
    }
  },
  StatusLabel: {
    Active: 'Active',
    Inactive: 'Inactive'
  },
  PrimaryCluster: {
    Title: 'Country',
    Subtitle: 'The primary cluster corresponds to the Country of operation.',
    Tooltip: "There's already a cluster for this country.",
    SuccessMessage: 'Group successfully created.',
    Confirm: 'Complete',
    Cancel: 'Go back',

    EditPrimaryCluster: {
      Title: 'Country',
      Subtitle: 'The primary cluster corresponds to the Country of operation.',
      Status: {
        Title: 'Status',
        Warning:
          "Switching to inactive will take effect immediately and will impact associated groups and sellers. You won't be able to submit task strategies to this country or manage groups. Proceed with caution."
      }
    }
  },
  GroupTabs: {
    LayoutCountry: {
      VendorsPool: 'Sellers',
      Groups: 'Groups',
      Create: 'Start creating groups',
      CreateMessage: 'Manage and arrange sellers in company groups.',
      CreateButton: 'Create group'
    },
    LayoutGroup: {
      VendorsPool: 'Non-grouped sellers',
      Groups: 'Seller subgroups',
      Create: 'Start creating subgroups',
      CreateMessage: 'Decentralized operations have task lists personalized by subgroup.',
      CreateButton: 'Create subgroup',
      Alert:
        'Create subgroups with the available sellers. You can only send tasks to sellers that belong to a subgroup.'
    }
  },
  Groups: {
    CreateNewGroupButton: 'Create new group',
    Vendors: 'Sellers in this group',
    Subgroups: 'Subgroups',
    TooltipMessage: 'All vendors are currently assigned to a group.',
    LayoutCountry: {
      CreateButton: 'Create new group',
      TabTitle: 'Groups',
      Vendors: 'Sellers in this group'
    },
    LayoutGroup: {
      CreateButton: 'Create new subgroup',
      TabTitle: 'Subgroups',
      Vendors: 'Sellers in this subgroup'
    },
    EditGroup: {
      GeneralDetailsCard: {
        Title: 'General information',
        Subtitle: 'Define the name and status of this company group.'
      },
      OperationModels: {
        Title: 'Operation model',
        Subtitle: 'The operation model defines how tasks are distributed.'
      },
      PermissionCard: {
        Title: 'Permission',
        Subtitle: 'Define who can have access to this group.'
      },
      GoBack: 'Go back',
      Save: 'Save'
    }
  },
  Vendors: {
    Table: {
      Id: 'ID',
      VendorName: 'Seller name',
      SearchPlaceholder: 'Search for seller ID or name',
      Actions: 'Actions',
      RemoveButton: 'Remove from group'
    },
    LayoutCountry: {
      AddToGroupBtn: 'Add to existing group',
      Modal: {
        Title: 'Send to groups',
        Subtitle: 'Select the group to which you would like to add {vendorsLength} new sellers.',
        SelectTitle: 'Groups',
        SelectPlaceholder: 'Select one option',
        CancelBtn: 'Cancel',
        ConfirmBtn: 'Confirm'
      }
    },
    LayoutGroup: {
      AddToGroupBtn: 'Add to existing subgroup',
      Modal: {
        Title: 'Send to subgroups',
        Subtitle: 'Select the subgroup to which you would like to add {vendorsLength} new sellers.',
        SelectTitle: 'Subgroups',
        SelectPlaceholder: 'Select one option',
        CancelBtn: 'Cancel',
        ConfirmBtn: 'Confirm'
      }
    }
  },
  Steps: {
    Details: {
      LayoutCountry: {
        Title: 'Group details',
        Description: 'Define the group information.'
      },
      LayoutGroup: {
        Title: 'Subgroup details',
        Description: 'Define the subgroup information.'
      }
    },
    Vendors: {
      LayoutCountry: {
        Title: 'Sellers addition',
        Description: 'Add related sellers to the group.'
      },
      LayoutGroup: {
        Title: 'Sellers addition',
        Description: 'Add related sellers to the subgroup.'
      }
    },
    Actions: {
      Next: 'Continue',
      GoBack: 'Go back',
      LayoutCountry: {
        Create: 'Add to group'
      },
      LayoutGroup: {
        Create: 'Add to subgroup'
      }
    }
  },

  DetailsStep: {
    LayoutCountry: {
      GeneralDetailsCard: {
        Title: 'General information',
        Description: 'Define the name and status of this company group.',
        Input: {
          Title: 'Group name',
          Hint: 'This name will be displayed on Company management. Example: Ambev BR.',
          Placeholder: 'Enter group name',
          Error: 'Remove any special characters and try again.'
        },
        Checkbox: {
          Title: 'Status',
          Hint: 'Define if the group is in operation. Active groups will be visible to the audience.',
          Active: 'Active',
          Inactive: 'Inactive'
        }
      },
      OperationModels: {
        Title: 'Operation model',
        Description: 'The operation model defines how tasks are distributed.',
        Centralized: {
          Title: 'Centralized',
          Description:
            'One task list for all sellers. Recommended when the same strategy applies to all.'
        },
        Decentralized: {
          Title: 'Decentralized',
          Description:
            'Task lists personalized by groups. Recommended when strategy varies by audience.'
        },
        CentralizedWarning: 'If you need to switch operational models later, contact the API team.',
        DisabledInfo: 'If you need to switch operational models, contact the API team.'
      },
      PermissionCard: {
        Title: 'Permission',
        Optional: '(optional)',
        Description: 'Define who can have access to this group.',
        Input: {
          Title: 'Group admin ',
          Hint: 'Group admins will see all groups and sellers within this group. Use enter to confirm values.',
          Placeholder: 'Enter emails',
          Error: 'Email not found or incorrect. Review and try again.',
          GroupError: 'This email is not in the same cluster as the subgroup.'
        }
      }
    },
    LayoutGroup: {
      GeneralDetailsCard: {
        Title: 'General information',
        Description: 'Define the name and status of this subgroup.',
        Input: {
          Title: 'Subgroup name',
          Hint: 'This name will be displayed on Company management. Example: Southeast zone.',
          Placeholder: 'Enter subgroup name',
          Error: 'Remove any special characters and try again.'
        },
        Checkbox: {
          Title: 'Status',
          Hint: 'Define if the subgroup is in operation. Active subgroups will be visible to the audience.',
          Active: 'Active',
          Inactive: 'Inactive'
        }
      },
      PermissionCard: {
        Title: 'Permission',
        Optional: '(optional)',
        Description: 'Define who can have access to this group.',
        Input: {
          Title: 'Group admin',
          Hint: 'Admins will be able to access the group information via other applications.',
          Placeholder: 'Enter emails',
          Error: 'Email not found or incorrect. Review and try again.',
          GroupError: 'This email is not in the same cluster as the group.'
        }
      },
      OperationModels: {
        Title: 'Operation model',
        Description: 'The operation model defines how tasks are distributed.',
        Centralized: {
          Title: 'Centralized',
          Description:
            'One task list for all sellers. Recommended when the same strategy applies to all.'
        },
        Decentralized: {
          Title: 'Decentralized',
          Description:
            'Task lists personalized by groups. Recommended when strategy varies by audience.'
        },
        CentralizedWarning: 'If you need to switch operational models later, contact the API team.',
        DisabledInfo: 'If you need to switch operational models, contact the API team.'
      }
    }
  },
  Table: {
    QuantityIndicator: 'of',
    ItemsPerPage: 'Lines per page: {option}',
    DeselectAll: 'Deselect all',
    OneSelected: 'line selected',
    MultipleSelected: 'lines selected',
    NoResultsFound: 'No results found'
  },
  OperationModel: {
    CENTRALIZED: 'Centralized',
    CentralizedMessage: 'One task list for all sellers. The same strategy applies to all.',
    DECENTRALIZED: 'Decentralized',
    DecentralizedMessage: 'Task lists personalized by groups. The strategy varies by audience.'
  },
  PageError: {
    Header: {
      notFound: 'Mmm... Looks like this page is gone',
      noPermission: 'Hmm... Access denied!'
    },
    SubHeader: {
      notFound: "It looks like this page wasn't found or doesn't exist.",
      noPermission: "It looks like you don't have permission to access this page."
    },
    ButtonLabel: 'Home'
  },
  Messages: {
    LayoutCountry: {
      SuccessSellersSentToGroup: 'Sellers sent to the group {groupName}.',
      ErrorSendingSellersToGroup: 'Unable to send sellers to group {groupName}.',
      SuccessGroupCreated: 'Group successfully created.',
      SuccessGroupUpdated: 'Group successfully updated.',

      ErrorCreatingGroup: 'Unable to create group.',
      ErrorUpdatingGroup: 'Unable to update group.'
    },
    LayoutGroup: {
      SuccessSellersSentToGroup: 'Sellers sent to the subgroup {groupName}.',
      ErrorSendingSellersToGroup: 'Unable to send sellers to subgroup {groupName}.',
      SuccessGroupCreated: 'Subgroup successfully created.',
      SuccessGroupUpdated: 'Group successfully updated.',

      ErrorCreatingGroup: 'Unable to create subgroup.',
      ErrorUpdatingGroup: 'Unable to update group.'
    }
  }
};

export default enUS;
