import { createContext, useCallback, useMemo, useReducer } from 'react';
import { IUserContext, IUserContextData, UpdateActions } from './UserContext.d';

const UserContext = createContext({} as IUserContext);

function reducer(state: any, action: UpdateActions): IUserContextData {
  return { ...state, ...action.payload };
}

export const initialState: IUserContextData = {
  data: { groupId: undefined, country: undefined, parentGroupId: undefined }
};

function UserProvider({ children }: Readonly<{ children: React.ReactNode }>) {
  const [state, dispatch] = useReducer(reducer, initialState);

  const onUpdateUser = useCallback((data) => {
    dispatch({ payload: { data } });
  }, []);

  const contextValue: IUserContext = useMemo(() => {
    return {
      data: state.data,
      updateData: onUpdateUser
    };
  }, [state.data, onUpdateUser]);

  return <UserContext.Provider value={contextValue}>{children}</UserContext.Provider>;
}

export { UserContext, UserProvider };
