import {
  ADMIN_PORTAL_VENDOR_COMPANY_MANAGEMENT_WRITE,
  ADMIN_PORTAL_VENDOR_GROUP_WRITE
} from 'config/constants';

/* eslint-disable no-prototype-builtins */
export const permissionsGroups = {
  admin: [ADMIN_PORTAL_VENDOR_COMPANY_MANAGEMENT_WRITE, ADMIN_PORTAL_VENDOR_GROUP_WRITE]
};

export const getPermissionGroup = (permission: keyof typeof permissionsGroups): string[] =>
  permissionsGroups.hasOwnProperty(permission) ? permissionsGroups[permission] : [];
