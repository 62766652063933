import { BASE_URL } from 'config/constants';
import { useMemo } from 'react';
import { useIntl } from 'react-intl';
import { useLocation, useParams } from 'react-router-dom';
import { routesMatch } from 'utils/routes/routesUtils';
import { IRoute } from './useVendorsRoutes.d';

function useVendorsRoutes() {
  const { formatMessage } = useIntl();
  const { pathname } = useLocation();
  const params = useParams();

  const vendorsRoutes: IRoute[] = useMemo(
    () => [
      {
        path: BASE_URL,
        title: formatMessage({ id: `Header.SellersManagement` }),
        showBreadcrumb: false
      },
      {
        path: `${BASE_URL}/create/primary-cluster`,
        title: formatMessage({ id: `Header.NewPrimaryCluster` }),
        showBreadcrumb: true
      },
      {
        path: `${BASE_URL}/root/${params.rootId}/create-group/details`,
        title: formatMessage({ id: `Header.CreateGroup` }),
        showBreadcrumb: true
      },
      {
        path: `${BASE_URL}/root/${params.rootId}/create-group/vendors`,
        title: formatMessage({ id: `Header.CreateGroup` }),
        showBreadcrumb: true
      },
      {
        path: `${BASE_URL}/root/${params.rootId}`,
        title: formatMessage({ id: `Header.RootDetails` }),
        hideHeader: true,
        showBreadcrumb: false
      },
      {
        path: `${BASE_URL}/root/${params.rootId}/edit-primary-cluster`,
        title: formatMessage({ id: `Header.PrimaryCluster` }),
        showBreadcrumb: true
      },
      {
        path: `${BASE_URL}/root/${params.rootId}/group/${params.groupId}`,
        title: formatMessage({ id: `Header.GroupDetails` }),
        showBreadcrumb: true
      },
      {
        path: `${BASE_URL}/root/${params.rootId}/group/${params.groupId}/edit-group`,
        title: formatMessage({ id: `Header.EditGroup` }),
        showBreadcrumb: true
      },
      {
        path: `${BASE_URL}/root/${params.rootId}/group/${params.groupId}/subgroup/${params.subGroupId}`,
        title: formatMessage({ id: `Header.SubgroupDetails` }),
        showBreadcrumb: true
      },
      {
        path: `${BASE_URL}/root/${params.rootId}/group/${params.groupId}/create-subgroup/details`,
        title: formatMessage({ id: `Header.CreateSubgroup` }),
        showBreadcrumb: true
      },
      {
        path: `${BASE_URL}/root/${params.rootId}/group/${params.groupId}/create-subgroup/vendors`,
        title: formatMessage({ id: `Header.CreateSubgroup` }),
        showBreadcrumb: true
      },
      {
        path: `${BASE_URL}/root/${params.rootId}/group/${params.groupId}/edit-group`,
        title: formatMessage({ id: `Header.EditGroup` }),
        showBreadcrumb: true
      },
      {
        path: `${BASE_URL}/root/${params.rootId}/group/${params.groupId}/subgroup/${params.subGroupId}/edit-group`,
        title: formatMessage({ id: `Header.EditSubgroup` }),
        showBreadcrumb: true
      }
    ],
    [formatMessage, params]
  );

  const activeRoute = routesMatch(vendorsRoutes, pathname);

  return { vendorsRoutes, activeRoute };
}

export default useVendorsRoutes;
