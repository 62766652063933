import { styled } from '@hexa-ui/theme';

const AppContainer = styled('div', {
  display: 'grid',
  paddingBottom: '$8',
  minHeight: 'calc(100vh - 2 * 56px - $$headerHeight)',
  '@desktopSmall': {
    minHeight: 'calc(100vh - 56px - $$headerHeight)'
  }
});

const Styled = {
  AppContainer
};

export default Styled;
