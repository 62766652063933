import { BASE_URL } from 'config/constants';
import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import { PageErrorProps } from './PageError.d';
import Styled from './PageError.styles';

function PageError({ redirectTo, buttonLabel, errorType }: Readonly<PageErrorProps>) {
  const { formatMessage } = useIntl();
  const navigate = useNavigate();

  const handleClick = () => {
    navigate(redirectTo || BASE_URL);
  };

  if (errorType === 'noPermission') {
    return (
      <Styled.ErrorContainer data-testid="pageError">
        <Styled.Error403Styled
          header={formatMessage({ id: `PageError.Header.${errorType}` })}
          description={formatMessage({ id: `PageError.SubHeader.${errorType}` })}
          buttonText={buttonLabel || formatMessage({ id: 'PageError.ButtonLabel' })}
          onButtonClick={handleClick}
        />
      </Styled.ErrorContainer>
    );
  }

  return (
    <Styled.ErrorContainer data-testid="pageError">
      <Styled.Error404Styled
        headerText={formatMessage({ id: `PageError.Header.notFound` })}
        subHeaderText={formatMessage({ id: `PageError.SubHeader.notFound` })}
        buttonText={buttonLabel || formatMessage({ id: 'PageError.ButtonLabel' })}
        buttonClickHandler={handleClick}
      />
    </Styled.ErrorContainer>
  );
}

export default PageError;
