import { useLocation } from 'react-router-dom';

const useGetPathType = () => {
  const location = useLocation();
  if (location.pathname.includes('/subgroup/') || location.pathname.includes('/group/')) {
    return 'LayoutGroup';
  }

  return 'LayoutCountry';
};

export default useGetPathType;
