import MessageMap from '../i18n.d';

const ptBR: MessageMap = {
  test: {
    translate: 'Traduzir',
    missing: 'Apenas opção em inglês disponível'
  },
  HomePage: {
    Header: {
      SelectPrimaryCluster: 'Selecione um cluster primário',
      HeaderTooltip: 'Um cluster primário corresponde ao país de operação.',
      Subtitle: 'Selecione para acessar a lista de vendedores e subgrupos.',
      CreateClusterButton: 'Criar novo cluster primário'
    }
  },
  Sidebar: {
    Home: 'Início'
  },
  Header: {
    MainTitle: 'Gerenciamento de vendedores',
    SellersManagement: 'Gerenciamento de vendedores',
    NewPrimaryCluster: 'Novo cluster primário',
    CreateGroup: 'Criar grupo',
    CreateSubgroup: 'Criar subgrupo',
    RootDetails: 'Detalhes do país',
    GroupDetails: 'Detalhes do grupo',
    SubgroupDetails: 'Detalhes do subgrupo',
    EditGroup: 'Editar grupo',
    EditSubgroup: 'Editar subgrupo'
  },
  EmptyMessage: {
    Title: 'Criar primeiro cluster',
    Subtitle: 'Um cluster primário corresponde ao país de operação.',
    Action: 'Criar cluster primário',
    LayoutCountry: {
      NoVendors: 'Nenhum vendedor disponível',
      NoVendorsDescription:
        'Todos os vendedores estão atribuídos a um grupo. Aguarde uma atualização ou remova vendedores dos grupos para realocá-los.',
      ReturnButton: 'Retornar aos grupos'
    },
    LayoutGroup: {
      NoVendors: 'Nenhum vendedor disponível',
      NoVendorsDescription:
        'Todos os vendedores estão atribuídos a um subgrupo. Aguarde uma atualização ou remova vendedores dos subgrupos para realocá-los.',
      ReturnButton: 'Retornar aos subgrupos'
    }
  },
  CardCountry: {
    Vendors: '{vendors, plural, one {{vendors} vendedor} other {{vendors} vendedores}}',
    Sellers: '{vendors, plural, one {{vendors} vendedor} other {{vendors} vendedores}}',
    Groups: '{groups, plural, one {{groups} grupo de vendedores} other {{groups} grupos}}',
    Subgroups: 'subgrupos',
    LayoutCountry: {
      Groups: '{groups, plural, one {{groups} grupo de vendedores} other {{groups} grupos}}',
      Vendors: '{vendors, plural, one {{vendors} vendedor} other {{vendors} vendedores}}'
    },
    LayoutGroup: {
      Groups:
        '{groups, plural, one {{groups} subgrupo de vendedores} other {{groups} subgrupos de vendedores}}',
      Vendors: '{vendors, plural, one {{vendors} vendedor} other {{vendors} vendedores}}'
    }
  },
  StatusLabel: {
    Active: 'Ativo',
    Inactive: 'Inativo'
  },
  PrimaryCluster: {
    Title: 'País',
    Subtitle: 'O cluster primário corresponde ao país de operação.',
    Tooltip: 'Já existe um cluster para este país.',
    SuccessMessage: 'Grupo criado com sucesso.',
    Confirm: 'Concluir',
    Cancel: 'Voltar'
  },
  GroupTabs: {
    LayoutCountry: {
      VendorsPool: 'Vendedores',
      Groups: 'Grupos',
      Create: 'Comece a criar grupos',
      CreateMessage: 'Gerencie e organize vendedores em grupos da empresa.',
      CreateButton: 'Criar grupo'
    },
    LayoutGroup: {
      VendorsPool: 'Vendedores não agrupados',
      Groups: 'Subgrupos de vendedores',
      Create: 'Comece a criar subgrupos',
      CreateMessage:
        'Operações descentralizadas têm listas de tarefas personalizadas por subgrupo.',
      CreateButton: 'Criar subgrupo',
      Alert:
        'Crie subgrupos com os vendedores disponíveis. Você só pode enviar tarefas para vendedores que pertencem a um subgrupo.'
    }
  },
  Groups: {
    CreateNewGroupButton: 'Criar novo grupo',
    Vendors: 'Vendedores neste grupo',
    Subgroups: 'Subgrupos',
    TooltipMessage: 'Todos os vendedores estão atualmente atribuídos a um grupo.',
    LayoutCountry: {
      CreateButton: 'Criar novo grupo',
      TabTitle: 'Grupos',
      Vendors: 'Vendedores neste grupo'
    },
    LayoutGroup: {
      CreateButton: 'Criar novo subgrupo',
      TabTitle: 'Subgrupos',
      Vendors: 'Vendedores neste subgrupo'
    },
    EditGroup: {
      GeneralDetailsCard: {
        Title: 'Informações gerais',
        Subtitle: 'Defina o nome e o status deste grupo da empresa.'
      },
      OperationModels: {
        Title: 'Modelo de operação',
        Subtitle: 'O modelo de operação define como as tarefas são distribuídas.'
      },
      PermissionCard: {
        Title: 'Permissão',
        Subtitle: 'Defina quem pode ter acesso a este grupo.'
      },
      GoBack: 'Voltar',
      Save: 'Salvar'
    }
  },
  Vendors: {
    Table: {
      Id: 'ID',
      VendorName: 'Nome do vendedor',
      SearchPlaceholder: 'Pesquise por ID ou nome do vendedor',
      Actions: 'Ações',
      RemoveButton: 'Remover do grupo'
    },
    LayoutCountry: {
      AddToGroupBtn: 'Adicionar a um grupo existente',
      Modal: {
        Title: 'Enviar para grupos',
        Subtitle:
          'Selecione o grupo para o qual você gostaria de adicionar {vendorsLength} novos vendedores.',
        SelectTitle: 'Grupos',
        SelectPlaceholder: 'Selecione uma opção',
        CancelBtn: 'Cancelar',
        ConfirmBtn: 'Confirmar'
      }
    },
    LayoutGroup: {
      AddToGroupBtn: 'Adicionar a um subgrupo existente',
      Modal: {
        Title: 'Enviar para subgrupos',
        Subtitle:
          'Selecione o subgrupo para o qual você gostaria de adicionar {vendorsLength} novos vendedores.',
        SelectTitle: 'Subgrupos',
        SelectPlaceholder: 'Selecione uma opção',
        CancelBtn: 'Cancelar',
        ConfirmBtn: 'Confirmar'
      }
    }
  },
  Steps: {
    Details: {
      LayoutCountry: {
        Title: 'Detalhes do grupo',
        Description: 'Defina as informações do grupo.'
      },
      LayoutGroup: {
        Title: 'Detalhes do subgrupo',
        Description: 'Defina as informações do subgrupo.'
      }
    },
    Vendors: {
      LayoutCountry: {
        Title: 'Adição de vendedores',
        Description: 'Adicione vendedores relacionados ao grupo.'
      },
      LayoutGroup: {
        Title: 'Adição de vendedores',
        Description: 'Adicione vendedores relacionados ao subgrupo.'
      }
    },
    Actions: {
      Next: 'Continuar',
      GoBack: 'Voltar',
      LayoutCountry: {
        Create: 'Adicionar ao grupo'
      },
      LayoutGroup: {
        Create: 'Adicionar ao subgrupo'
      }
    }
  },

  DetailsStep: {
    LayoutCountry: {
      GeneralDetailsCard: {
        Title: 'Informações gerais',
        Description: 'Defina o nome e o status deste grupo da empresa.',
        Input: {
          Title: 'Nome do grupo',
          Hint: 'Este nome será exibido no gerenciamento da empresa. Exemplo: Ambev BR.',
          Placeholder: 'Digite o nome do grupo',
          Error: 'Remova quaisquer caracteres especiais e tente novamente.'
        },
        Checkbox: {
          Title: 'Status',
          Hint: 'Defina se o grupo está em operação. Grupos ativos serão visíveis para o público.',
          Active: 'Ativo',
          Inactive: 'Inativo'
        }
      },
      OperationModels: {
        Title: 'Modelo de operação',
        Description: 'O modelo de operação define como as tarefas são distribuídas.',
        Centralized: {
          Title: 'Centralizado',
          Description:
            'Uma lista de tarefas para todos os vendedores. Recomendado quando a mesma estratégia se aplica a todos.'
        },
        Decentralized: {
          Title: 'Descentralizado',
          Description:
            'Listas de tarefas personalizadas por grupos. Recomendado quando a estratégia varia por público.'
        },
        CentralizedWarning:
          'Se você precisar mudar os modelos operacionais mais tarde, entre em contato com a equipe de API.',
        DisabledInfo:
          'Se você precisar mudar os modelos operacionais, entre em contato com a equipe de API.'
      },
      PermissionCard: {
        Title: 'Permissão',
        Optional: '(opcional)',
        Description: 'Defina quem pode ter acesso a este grupo.',
        Input: {
          Title: 'Admin do grupo',
          Hint: 'Os admins do grupo verão todos os grupos e vendedores dentro deste grupo. Use enter para confirmar valores.',
          Placeholder: 'Digite emails',
          Error: 'Email não encontrado ou incorreto. Revise e tente novamente.',
          GroupError: 'Este email não está no mesmo cluster que o subgrupo.'
        }
      }
    },
    LayoutGroup: {
      GeneralDetailsCard: {
        Title: 'Informações gerais',
        Description: 'Defina o nome e o status deste subgrupo.',
        Input: {
          Title: 'Nome do subgrupo',
          Hint: 'Este nome será exibido no gerenciamento da empresa. Exemplo: Zona Sudeste.',
          Placeholder: 'Digite o nome do subgrupo',
          Error: 'Remova quaisquer caracteres especiais e tente novamente.'
        },
        Checkbox: {
          Title: 'Status',
          Hint: 'Defina se o subgrupo está em operação. Subgrupos ativos serão visíveis para o público.',
          Active: 'Ativo',
          Inactive: 'Inativo'
        }
      },
      PermissionCard: {
        Title: 'Permissão',
        Optional: '(opcional)',
        Description: 'Defina quem pode ter acesso a este grupo.',
        Input: {
          Title: 'Admin do grupo',
          Hint: 'Os admins poderão acessar as informações do grupo por meio de outros aplicativos.',
          Placeholder: 'Digite emails',
          Error: 'Email não encontrado ou incorreto. Revise e tente novamente.',
          GroupError: 'Este email não está no mesmo cluster que o grupo.'
        }
      },
      OperationModels: {
        Title: 'Modelo de operação',
        Description: 'O modelo de operação define como as tarefas são distribuídas.',
        Centralized: {
          Title: 'Centralizado',
          Description:
            'Uma lista de tarefas para todos os vendedores. Recomendado quando a mesma estratégia se aplica a todos.'
        },
        Decentralized: {
          Title: 'Descentralizado',
          Description:
            'Listas de tarefas personalizadas por grupos. Recomendado quando a estratégia varia por público.'
        },
        CentralizedWarning:
          'Se você precisar mudar os modelos operacionais mais tarde, entre em contato com a equipe de API.',
        DisabledInfo:
          'Se você precisar mudar os modelos operacionais, entre em contato com a equipe de API.'
      }
    }
  },
  Table: {
    QuantityIndicator: 'de',
    ItemsPerPage: 'Linhas por página: {option}',
    DeselectAll: 'Desmarcar todos',
    OneSelected: 'linha selecionada',
    MultipleSelected: 'linhas selecionadas',
    NoResultsFound: 'Nenhum resultado encontrado'
  },
  OperationModel: {
    CENTRALIZED: 'Centralizado',
    CentralizedMessage:
      'Uma lista de tarefas para todos os vendedores. A mesma estratégia se aplica a todos.',
    DECENTRALIZED: 'Descentralizado',
    DecentralizedMessage:
      'Listas de tarefas personalizadas por grupos. A estratégia varia de acordo com o público.'
  },
  PageError: {
    Header: {
      notFound: 'Mmm... Parece que esta página se foi',
      noPermission: 'Hmm... Acesso negado!'
    },
    SubHeader: {
      notFound: 'Parece que esta página não foi encontrada ou não existe.',
      noPermission: 'Parece que você não tem permissão para acessar esta página.'
    },
    ButtonLabel: 'Início'
  },
  Messages: {
    LayoutCountry: {
      SuccessSellersSentToGroup: 'Vendedores enviados para o grupo {groupName}.',
      ErrorSendingSellersToGroup: 'Não foi possível enviar vendedores para o grupo {groupName}.',
      SuccessGroupCreated: 'Grupo criado com sucesso.',
      SuccessGroupUpdated: 'Grupo atualizado com sucesso.',

      ErrorCreatingGroup: 'Não foi possível criar o grupo.',
      ErrorUpdatingGroup: 'Não foi possível atualizar o grupo.'
    },
    LayoutGroup: {
      SuccessSellersSentToGroup: 'Vendedores enviados para o subgrupo {groupName}.',
      ErrorSendingSellersToGroup: 'Não foi possível enviar vendedores para o subgrupo {groupName}.',
      SuccessGroupCreated: 'Subgrupo criado com sucesso.',
      SuccessGroupUpdated: 'Grupo atualizado com sucesso.',

      ErrorCreatingGroup: 'Não foi possível criar o subgrupo.',
      ErrorUpdatingGroup: 'Não foi possível atualizar o grupo.'
    }
  }
};

export default ptBR;
