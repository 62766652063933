import { Country } from 'hooks/useCountryCluster/useCountryCluster.types';
import { useState } from 'react';
import CountryCard from '../CountryCard/CountryCard';
import { CountryCardGroupProps } from './CountryCardGroup.d';
import Styled from './CountryCardGroup.styles';

const CountryCardGroup = ({ countries, onChangeCountry }: CountryCardGroupProps) => {
  const [selected, setSelected] = useState<Country>(null);

  const changeSelcted = (country: Country) => {
    if (country.isDisabled) return;

    setSelected(country);

    if (onChangeCountry) onChangeCountry(country);
  };

  return (
    <Styled.Container>
      {countries.map((item) => (
        <CountryCard
          key={item.code}
          name={item.name}
          urlImage={item.icon}
          isChecked={selected?.name === item.name}
          isDisabled={item.isDisabled}
          onClick={() => changeSelcted(item)}
        />
      ))}
    </Styled.Container>
  );
};

export default CountryCardGroup;
