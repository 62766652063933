import MessageMap from 'i18n/i18n';

const es419: MessageMap = {
  test: {
    translate: 'Traducir',
    missing: 'Solo opción en inglés disponible'
  },
  HomePage: {
    Header: {
      SelectPrimaryCluster: 'Selecciona un cluster primario',
      HeaderTooltip: 'Un cluster primario corresponde al país de operación.',
      Subtitle: 'Selecciona para acceder a la lista de vendedores y subgrupos.',
      CreateClusterButton: 'Crear nuevo cluster primario'
    }
  },
  Sidebar: {
    Home: 'Inicio'
  },
  Header: {
    MainTitle: 'Gestión de vendedores',
    SellersManagement: 'Gestión de vendedores',
    NewPrimaryCluster: 'Nuevo cluster primario',
    CreateGroup: 'Crear grupo',
    CreateSubgroup: 'Crear subgrupo',
    RootDetails: 'Detalles del país',
    GroupDetails: 'Detalles del grupo',
    SubgroupDetails: 'Detalles del subgrupo',
    EditGroup: 'Editar grupo',
    EditSubgroup: 'Editar subgrupo'
  },
  EmptyMessage: {
    Title: 'Crear primer cluster',
    Subtitle: 'Un cluster primario corresponde al país de operación.',
    Action: 'Crear cluster primario',
    LayoutCountry: {
      NoVendors: 'No hay vendedores disponibles',
      NoVendorsDescription:
        'Todos los vendedores están asignados a un grupo. Espera una actualización o elimina vendedores de los grupos para reasignarlos.',
      ReturnButton: 'Volver a los grupos'
    },
    LayoutGroup: {
      NoVendors: 'No hay vendedores disponibles',
      NoVendorsDescription:
        'Todos los vendedores están asignados a un subgrupo. Espera una actualización o elimina vendedores de los subgrupos para reasignarlos.',
      ReturnButton: 'Volver a los subgrupos'
    }
  },
  CardCountry: {
    Vendors: '{vendors, plural, one {{vendors} vendedor} other {{vendors} vendedores}}',
    Sellers: '{vendors, plural, one {{vendors} vendedor} other {{vendors} vendedores}}',
    Groups: '{groups, plural, one {{groups} grupo de vendedores} other {{groups} grupos}}',
    Subgroups: 'subgrupos',
    LayoutCountry: {
      Groups: '{groups, plural, one {{groups} grupo de vendedores} other {{groups} grupos}}',
      Vendors: '{vendors, plural, one {{vendors} vendedor} other {{vendors} vendedores}}'
    },
    LayoutGroup: {
      Groups:
        '{groups, plural, one {{groups} subgrupo de vendedores} other {{groups} subgrupos de vendedores}}',
      Vendors: '{vendors, plural, one {{vendors} vendedor} other {{vendors} vendedores}}'
    }
  },
  StatusLabel: {
    Active: 'Activo',
    Inactive: 'Inactivo'
  },
  PrimaryCluster: {
    Title: 'País',
    Subtitle: 'El cluster primario corresponde al país de operación.',
    Tooltip: 'Ya existe un cluster para este país.',
    SuccessMessage: 'Grupo creado con éxito.',
    Confirm: 'Completar',
    Cancel: 'Volver'
  },
  GroupTabs: {
    LayoutCountry: {
      VendorsPool: 'Vendedores',
      Groups: 'Grupos',
      Create: 'Comienza a crear grupos',
      CreateMessage: 'Gestiona y organiza vendedores en grupos de la empresa.',
      CreateButton: 'Crear grupo'
    },
    LayoutGroup: {
      VendorsPool: 'Vendedores no agrupados',
      Groups: 'Subgrupos de vendedores',
      Create: 'Comienza a crear subgrupos',
      CreateMessage:
        'Las operaciones descentralizadas tienen listas de tareas personalizadas por subgrupo.',
      CreateButton: 'Crear subgrupo',
      Alert:
        'Crea subgrupos con los vendedores disponibles. Solo puedes enviar tareas a vendedores que pertenezcan a un subgrupo.'
    }
  },
  Groups: {
    CreateNewGroupButton: 'Crear nuevo grupo',
    Vendors: 'Vendedores en este grupo',
    Subgroups: 'Subgrupos',
    TooltipMessage: 'Todos los vendedores están actualmente asignados a un grupo.',
    LayoutCountry: {
      CreateButton: 'Crear nuevo grupo',
      TabTitle: 'Grupos',
      Vendors: 'Vendedores en este grupo'
    },
    LayoutGroup: {
      CreateButton: 'Crear nuevo subgrupo',
      TabTitle: 'Subgrupos',
      Vendors: 'Vendedores en este subgrupo'
    },
    EditGroup: {
      GeneralDetailsCard: {
        Title: 'Información general',
        Subtitle: 'Define el nombre y el estado de este grupo de la empresa.'
      },
      OperationModels: {
        Title: 'Modelo de operación',
        Subtitle: 'El modelo de operación define cómo se distribuyen las tareas.'
      },
      PermissionCard: {
        Title: 'Permiso',
        Subtitle: 'Define quién puede tener acceso a este grupo.'
      },
      GoBack: 'Volver',
      Save: 'Guardar'
    }
  },
  Vendors: {
    Table: {
      Id: 'ID',
      VendorName: 'Nombre del vendedor',
      SearchPlaceholder: 'Buscar por ID o nombre del vendedor',
      Actions: 'Acciones',
      RemoveButton: 'Eliminar del grupo'
    },
    LayoutCountry: {
      AddToGroupBtn: 'Agregar a un grupo existente',
      Modal: {
        Title: 'Enviar a grupos',
        Subtitle:
          'Selecciona el grupo al que te gustaría agregar {vendorsLength} nuevos vendedores.',
        SelectTitle: 'Grupos',
        SelectPlaceholder: 'Selecciona una opción',
        CancelBtn: 'Cancelar',
        ConfirmBtn: 'Confirmar'
      }
    },
    LayoutGroup: {
      AddToGroupBtn: 'Agregar a un subgrupo existente',
      Modal: {
        Title: 'Enviar a subgrupos',
        Subtitle:
          'Selecciona el subgrupo al que te gustaría agregar {vendorsLength} nuevos vendedores.',
        SelectTitle: 'Subgrupos',
        SelectPlaceholder: 'Selecciona una opción',
        CancelBtn: 'Cancelar',
        ConfirmBtn: 'Confirmar'
      }
    }
  },
  Steps: {
    Details: {
      LayoutCountry: {
        Title: 'Detalles del grupo',
        Description: 'Define la información del grupo.'
      },
      LayoutGroup: {
        Title: 'Detalles del subgrupo',
        Description: 'Define la información del subgrupo.'
      }
    },
    Vendors: {
      LayoutCountry: {
        Title: 'Adición de vendedores',
        Description: 'Agrega vendedores relacionados al grupo.'
      },
      LayoutGroup: {
        Title: 'Adición de vendedores',
        Description: 'Agrega vendedores relacionados al subgrupo.'
      }
    },
    Actions: {
      Next: 'Continuar',
      GoBack: 'Volver',
      LayoutCountry: {
        Create: 'Agregar al grupo'
      },
      LayoutGroup: {
        Create: 'Agregar al subgrupo'
      }
    }
  },

  DetailsStep: {
    LayoutCountry: {
      GeneralDetailsCard: {
        Title: 'Información general',
        Description: 'Define el nombre y el estado de este grupo de la empresa.',
        Input: {
          Title: 'Nombre del grupo',
          Hint: 'Este nombre se mostrará en la gestión de la empresa. Ejemplo: Ambev BR.',
          Placeholder: 'Ingresa el nombre del grupo',
          Error: 'Elimina cualquier carácter especial e intenta de nuevo.'
        },
        Checkbox: {
          Title: 'Estado',
          Hint: 'Define si el grupo está en operación. Los grupos activos serán visibles para la audiencia.',
          Active: 'Activo',
          Inactive: 'Inactivo'
        }
      },
      OperationModels: {
        Title: 'Modelo de operación',
        Description: 'El modelo de operación define cómo se distribuyen las tareas.',
        Centralized: {
          Title: 'Centralizado',
          Description:
            'Una lista de tareas para todos los vendedores. Recomendado cuando la misma estrategia se aplica a todos.'
        },
        Decentralized: {
          Title: 'Descentralizado',
          Description:
            'Listas de tareas personalizadas por grupos. Recomendado cuando la estrategia varía por audiencia.'
        },
        CentralizedWarning:
          'Si necesitas cambiar los modelos operativos más tarde, contacta al equipo de API.',
        DisabledInfo: 'Si necesitas cambiar los modelos operativos, contacta al equipo de API.'
      },
      PermissionCard: {
        Title: 'Permiso',
        Optional: '(opcional)',
        Description: 'Define quién puede tener acceso a este grupo.',
        Input: {
          Title: 'Admin del grupo ',
          Hint: 'Los admins del grupo verán todos los grupos y vendedores dentro de este grupo. Usa enter para confirmar valores.',
          Placeholder: 'Ingresa correos electrónicos',
          Error: 'Correo electrónico no encontrado o incorrecto. Revisa e intenta de nuevo.',
          GroupError: 'Este correo electrónico no está en el mismo cluster que el subgrupo.'
        }
      }
    },
    LayoutGroup: {
      GeneralDetailsCard: {
        Title: 'Información general',
        Description: 'Define el nombre y el estado de este subgrupo.',
        Input: {
          Title: 'Nombre del subgrupo',
          Hint: 'Este nombre se mostrará en la gestión de la empresa. Ejemplo: Zona sureste.',
          Placeholder: 'Ingresa el nombre del subgrupo',
          Error: 'Elimina cualquier carácter especial e intenta de nuevo.'
        },
        Checkbox: {
          Title: 'Estado',
          Hint: 'Define si el subgrupo está en operación. Los subgrupos activos serán visibles para la audiencia.',
          Active: 'Activo',
          Inactive: 'Inactivo'
        }
      },
      PermissionCard: {
        Title: 'Permiso',
        Optional: '(opcional)',
        Description: 'Define quién puede tener acceso a este grupo.',
        Input: {
          Title: 'Admin del grupo',
          Hint: 'Los admins podrán acceder a la información del grupo a través de otras aplicaciones.',
          Placeholder: 'Ingresa correos electrónicos',
          Error: 'Correo electrónico no encontrado o incorrecto. Revisa e intenta de nuevo.',
          GroupError: 'Este correo electrónico no está en el mismo cluster que el grupo.'
        }
      },
      OperationModels: {
        Title: 'Modelo de operación',
        Description: 'El modelo de operación define cómo se distribuyen las tareas.',
        Centralized: {
          Title: 'Centralizado',
          Description:
            'Una lista de tareas para todos los vendedores. Recomendado cuando la misma estrategia se aplica a todos.'
        },
        Decentralized: {
          Title: 'Descentralizado',
          Description:
            'Listas de tareas personalizadas por grupos. Recomendado cuando la estrategia varía por audiencia.'
        },
        CentralizedWarning:
          'Si necesitas cambiar los modelos operativos más tarde, contacta al equipo de API.',
        DisabledInfo: 'Si necesitas cambiar los modelos operativos, contacta al equipo de API.'
      }
    }
  },
  Table: {
    QuantityIndicator: 'de',
    ItemsPerPage: 'Líneas por página: {option}',
    DeselectAll: 'Deseleccionar todo',
    OneSelected: 'línea seleccionada',
    MultipleSelected: 'líneas seleccionadas',
    NoResultsFound: 'No se encontraron resultados'
  },
  OperationModel: {
    CENTRALIZED: 'Centralizado',
    CentralizedMessage:
      'Una lista de tareas para todos los vendedores. La misma estrategia se aplica a todos.',
    DECENTRALIZED: 'Descentralizado',
    DecentralizedMessage:
      'Listas de tareas personalizadas por grupos. La estrategia varía según la audiencia.'
  },
  PageError: {
    Header: {
      notFound: 'Mmm... Parece que esta página se ha ido',
      noPermission: 'Hmm... ¡Acceso denegado!'
    },
    SubHeader: {
      notFound: 'Parece que esta página no se encontró o no existe.',
      noPermission: 'Parece que no tienes permiso para acceder a esta página.'
    },
    ButtonLabel: 'Inicio'
  },
  Messages: {
    LayoutCountry: {
      SuccessSellersSentToGroup: 'Vendedores enviados al grupo {groupName}.',
      ErrorSendingSellersToGroup: 'No se pudo enviar vendedores al grupo {groupName}.',
      SuccessGroupCreated: 'Grupo creado exitosamente.',
      SuccessGroupUpdated: 'Grupo actualizado exitosamente.',

      ErrorCreatingGroup: 'No se pudo crear el grupo.',
      ErrorUpdatingGroup: 'No se pudo actualizar el grupo.'
    },
    LayoutGroup: {
      SuccessSellersSentToGroup: 'Vendedores enviados al subgrupo {groupName}.',
      ErrorSendingSellersToGroup: 'No se pudo enviar vendedores al subgrupo {groupName}.',
      SuccessGroupCreated: 'Subgrupo creado exitosamente.',
      SuccessGroupUpdated: 'Grupo actualizado exitosamente.',

      ErrorCreatingGroup: 'No se pudo crear el subgrupo.',
      ErrorUpdatingGroup: 'No se pudo actualizar el grupo.'
    }
  }
};

export default es419;
