import { Chip, LoadingDots, Paragraph } from '@hexa-ui/components';
import { AlertOctagon } from '@hexa-ui/icons';
import { useAuthenticationService } from 'admin-portal-shared-services';
import CardContainer from 'components/CardContainer/CardContainer';
import { AddUser } from 'context/CreateContext/CreateContext.d';
import useCreateContext from 'context/CreateContext/hooks/useCreateContext';
import useGetPathType from 'hooks/useGetPathType/useGetPathType';
import { useState } from 'react';
import { useIntl } from 'react-intl';
import { validateEmail } from 'services/identity-service/identity-service';
import { formatUserName } from 'utils/formatters/formatters';
import Styled from './PermissionCard.styles';

const PermissionCard = () => {
  const { formatMessage } = useIntl();
  const { group, fields, updateUser, removeUser, updateField, isEmailValidInClusterEU } =
    useCreateContext();
  const [inputValue, setInputValue] = useState('');
  const authentication = useAuthenticationService();
  const userLoggedCountry = authentication.getCountryB2C();

  const usersField = fields.users;
  const someUserIsLoading = group.users.some((user) => user.isLoading);

  const pageType = useGetPathType();
  const intlPrefix = `DetailsStep.${pageType}`;

  const handleDeleteChip = (user: AddUser) => {
    removeUser(user.email);
  };

  const handleInputChange = (event) => {
    const { value } = event.target;
    setInputValue(value);
  };

  const handleInputKeyDown = async (event) => {
    updateField('users', { hasError: false });

    const email = event.target.value?.toLowerCase();

    if (event.key !== 'Enter' || !email) return;
    validEmail(email);
  };

  const onBlurCapture = (event) => {
    const email = event.currentTarget.value?.toLowerCase();

    if (!email) return;
    validEmail(email);
  };

  const validEmail = async (email: string) => {
    const alreadyHasEmail = group.users.some((user) => user.email === email);
    if (alreadyHasEmail) {
      setInputValue('');
      return;
    }

    const isValid = updateUser({
      email,
      isLoading: true
    });

    if (!isValid) return;

    const hasUserFromService = await validateEmail(email);

    if (hasUserFromService) {
      if (
        !isEmailValidInClusterEU({
          userLoggedCountry,
          hasUserFromServiceCountry: hasUserFromService?.country
        })
      ) {
        updateField('users', { hasError: true, groupError: true });
        removeUser(email);
        return;
      }

      updateUser({
        id: hasUserFromService.id,
        name: formatUserName(email, hasUserFromService.displayName),
        isLoading: false,
        email,
        country: hasUserFromService.country
      });
      setInputValue('');
      return;
    }

    updateField('users', { hasError: true, groupError: false });
    removeUser(email);
  };

  const renderErrorMessage = () => {
    return (
      <Styled.PermissionCardErrorMessage>
        <AlertOctagon size="medium" />
        <Paragraph>
          {formatMessage({
            id: usersField.groupError
              ? `${intlPrefix}.PermissionCard.Input.GroupError`
              : `${intlPrefix}.PermissionCard.Input.Error`
          })}
        </Paragraph>
      </Styled.PermissionCardErrorMessage>
    );
  };

  return (
    <CardContainer
      title={formatMessage({ id: `${intlPrefix}.PermissionCard.Title` })}
      subtitle={formatMessage({ id: `${intlPrefix}.PermissionCard.Description` })}
    >
      <Styled.InputTitle>
        <Styled.PermissionCardLabel style={{ color: usersField.hasError ? '#C3332B' : '' }}>
          {formatMessage({ id: `${intlPrefix}.PermissionCard.Input.Title` })}
        </Styled.PermissionCardLabel>
        <Styled.PermissionCardHint>
          {formatMessage({ id: `${intlPrefix}.PermissionCard.Optional` })}
        </Styled.PermissionCardHint>
      </Styled.InputTitle>
      <Styled.PermissionCardHint>
        {formatMessage({ id: `${intlPrefix}.PermissionCard.Input.Hint` })}
      </Styled.PermissionCardHint>
      <Styled.Container style={{ outline: usersField.hasError ? '1px solid #C3332B' : '' }}>
        {!!group.users?.length && (
          <Styled.ChipBox>
            {group.users.map((user) =>
              user.isLoading ? (
                <LoadingDots
                  size="medium"
                  key={user.id}
                  style={{ marginLeft: '8px', minHeight: '32px' }}
                />
              ) : (
                <Chip.Root key={user.id} disabled={user?.disabled}>
                  <Chip.Label>{`${user.name} (${user.email})`}</Chip.Label>
                  <Chip.DeleteIcon onDelete={() => handleDeleteChip(user)} />
                </Chip.Root>
              )
            )}
          </Styled.ChipBox>
        )}

        <Styled.Input
          data-testid="permission-card-input"
          placeholder={formatMessage({
            id: `${intlPrefix}.PermissionCard.Input.Placeholder`
          })}
          value={!someUserIsLoading ? inputValue : ''}
          onChange={handleInputChange}
          onBlurCapture={onBlurCapture}
          type="text"
          onKeyDown={handleInputKeyDown}
        />
      </Styled.Container>
      {usersField.hasError && renderErrorMessage()}
    </CardContainer>
  );
};

export default PermissionCard;
