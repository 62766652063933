import { Paragraph, Input as _Input } from '@hexa-ui/components';
import { styled } from '@hexa-ui/theme';

const Container = styled('div', {
  display: 'flex',
  flexWrap: 'wrap',
  alignItems: 'center',
  outline: '1px solid $interfaceSurfaceSecondary',
  borderRadius: '$2',
  padding: '$1',
  '& > div': {
    '& > *:nth-child(1)': {
      outline: 'none !important'
    }
  }
});

const ChipBox = styled('div', {
  display: 'flex',
  flexWrap: 'wrap',
  gap: '$2',
  margin: '$2',
  marginRight: 0
});

const Input = styled(_Input, {
  flex: 1,
  minWidth: '300px'
});

const PermissionCardLabel = styled(Paragraph, {
  fontSize: '$2',
  fontWeight: '$semibold !important',
  color: '$interfaceLabelPrimary'
});

const PermissionCardHint = styled(Paragraph, {
  fontSize: '$1',
  fontWeight: '$normal',
  color: '$interfaceLabelSecondary !important'
});

const InputTitle = styled('div', {
  display: 'flex',
  gap: '4px'
});

const PermissionCardErrorMessage = styled('div', {
  display: 'flex',
  gap: '4px',
  color: '#C3332B',
  alignItems: 'center',
  '& > p': {
    color: '#C3332B',
    fontSize: '$1'
  }
});

export const Styled = {
  Container,
  ChipBox,
  Input,
  PermissionCardLabel,
  PermissionCardHint,
  InputTitle,
  PermissionCardErrorMessage
};

export default Styled;
