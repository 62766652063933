import Api from 'Api/Api';
import {
  useLogService as logService,
  useAuthenticationService
} from 'admin-portal-shared-services';
import { VENDORS_GROUP_SERVICE_BASE_URL, getServiceDomain } from 'config/constants';
import { Group, GroupMinimal, GroupToCreate, GroupToUpdate, UsersFromGroup } from 'domain/Group.d';
import { User } from 'domain/User';
import { Vendor, VendorByCountryResponse } from 'domain/Vendor';
import { Country } from 'hooks/useCountryCluster/useCountryCluster.types';
import { isClusterEuUser } from 'utils/validations';
import { PostGroupResponse } from './group-service.types';

export const getGroupById = async (groupId: string): Promise<Group> => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const authentication = useAuthenticationService();

  const { user_country: userCountry } = authentication.getUserCountryAndLanguage();
  const isCountryEu = isClusterEuUser(userCountry);

  const log = logService();

  try {
    const response = await Api.get({
      url: `${getServiceDomain(isCountryEu)}${VENDORS_GROUP_SERVICE_BASE_URL}/groups/${groupId}`,
      config: {
        params: { includeChildGroups: true }
      }
    });

    return response?.data;
  } catch (error) {
    const responseMessage = error?.response?.data?.message ?? error?.response?.data?.messages?.[0];
    log.error(
      `group-service.getGroupById: Error - ${error.message}.${
        responseMessage ? ` Message: ${responseMessage}` : ''
      }`,
      error
    );
    throw error;
  }
};

export const postGroup = async (group: GroupToCreate): Promise<PostGroupResponse> => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const authentication = useAuthenticationService();
  const { user_country: userCountry } = authentication.getUserCountryAndLanguage();
  const isCountryEu = isClusterEuUser(userCountry);

  const log = logService();

  try {
    const response = await Api.post({
      url: `${getServiceDomain(isCountryEu)}${VENDORS_GROUP_SERVICE_BASE_URL}/groups`,
      body: group
    });

    return response.data;
  } catch (error) {
    const responseMessage = error?.response?.data?.message ?? error?.response?.data?.messages?.[0];
    log.error(
      `group-service.postGroup: Error - ${error.message}.${
        responseMessage ? ` Message: ${responseMessage}` : ''
      }`,
      error
    );
    throw error;
  }
};

export const addVendorsToGroupByGroupId = async (
  groupId: string,
  vendors: Vendor[]
): Promise<void> => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const authentication = useAuthenticationService();
  const { user_country: userCountry } = authentication.getUserCountryAndLanguage();
  const isCountryEu = isClusterEuUser(userCountry);

  const log = logService();

  try {
    await Api.post({
      url: `${getServiceDomain(
        isCountryEu
      )}${VENDORS_GROUP_SERVICE_BASE_URL}/groups/${groupId}/vendors`,
      body: vendors
    });
  } catch (error) {
    const responseMessage = error?.response?.data?.message ?? error?.response?.data?.messages?.[0];
    log.error(
      `group-service.addVendorsToGroupByGroupId: Error - ${error.message}.${
        responseMessage ? ` Message: ${responseMessage}` : ''
      }`,
      error
    );
    throw error;
  }
};

export const getAllGroups = async (): Promise<{ content: GroupMinimal[] }> => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const authentication = useAuthenticationService();
  const { user_country: userCountry } = authentication.getUserCountryAndLanguage();
  const isCountryEu = isClusterEuUser(userCountry);

  const log = logService();

  try {
    const response = await Api.get({
      url: `${getServiceDomain(isCountryEu)}${VENDORS_GROUP_SERVICE_BASE_URL}/groups`,
      config: {
        params: { includeChildGroups: false }
      }
    });

    return response?.data;
  } catch (error) {
    const responseMessage = error?.response?.data?.message ?? error?.response?.data?.messages?.[0];
    log.error(
      `group-service.getAllGroups: Error - ${error.message}.${
        responseMessage ? ` Message: ${responseMessage}` : ''
      }`,
      error
    );
    throw error;
  }
};

export const patchGroup = async (group: GroupToUpdate, groupId: string): Promise<void> => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const authentication = useAuthenticationService();
  const { user_country: userCountry } = authentication.getUserCountryAndLanguage();
  const isCountryEu = isClusterEuUser(userCountry);

  const log = logService();

  try {
    await Api.patch({
      url: `${getServiceDomain(isCountryEu)}${VENDORS_GROUP_SERVICE_BASE_URL}/groups/${groupId}`,
      body: group
    });
  } catch (error) {
    const responseMessage = error?.response?.data?.message ?? error?.response?.data?.messages?.[0];
    log.error(
      `group-service.patchGroup: Error - ${error.message}.${
        responseMessage ? ` Message: ${responseMessage}` : ''
      }`,
      error
    );
    throw error;
  }
};

export const getUsersFromGroup = async (groupId: string, page?: number): Promise<UsersFromGroup> => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const authentication = useAuthenticationService();
  const { user_country: userCountry } = authentication.getUserCountryAndLanguage();
  const isCountryEu = isClusterEuUser(userCountry);

  const log = logService();

  try {
    const response = await Api.get({
      url: `${getServiceDomain(
        isCountryEu
      )}${VENDORS_GROUP_SERVICE_BASE_URL}/groups/${groupId}/users`,
      config: {
        params: { page: page? page : 0 }
      }
    });

    return response?.data;
  } catch (error) {
    const responseMessage = error?.response?.data?.message ?? error?.response?.data?.messages?.[0];
    log.error(
      `group-service.getUsersFromGroup: Error - ${error.message}.${
        responseMessage ? ` Message: ${responseMessage}` : ''
      }`,
      error
    );
    throw error;
  }
};

export const getVendorsByCountry = async (country: string): Promise<VendorByCountryResponse[]> => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const authentication = useAuthenticationService();
  const { user_country: userCountry } = authentication.getUserCountryAndLanguage();
  const isCountryEu = isClusterEuUser(userCountry);

  const log = logService();

  const headers = { Country: country };

  try {
    const vendors = await Api.get({
      url: `${getServiceDomain(isCountryEu)}/vendors/info`,
      config: {
        headers
      }
    });
    return vendors.data;
  } catch (error) {
    const responseMessage = error?.response?.data?.message ?? error?.response?.data?.messages?.[0];
    log.error(
      `vendor-service.getVendorsByCountry: Error - ${error.message}.${
        responseMessage ? ` Message: ${responseMessage}` : ''
      }`,
      error
    );
    throw error;
  }
};

export const createRoot = async (country: Country, users?: User[]): Promise<string> => {
  const rawVendors = await getVendorsByCountry(country.code);
  const vendors: Vendor[] = rawVendors
    .filter((vendor) => !!vendor.legalName && vendor.legalName.length < 80)
    .map((vendor) => ({
      id: vendor.vendorId,
      name: vendor.legalName,
      assigned: false
    }));

  const root: GroupToCreate = {
    name: country.name,
    icon: country.icon,
    enabled: true,
    parentGroupId: 'root',
    vendors,
    users,
    country: country.code
  };

  const response = await postGroup(root);
  return response.groupId;
};

export const getAllRoots = async (): Promise<{ content: Group[] }> => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const authentication = useAuthenticationService();
  const { user_country: userCountry } = authentication.getUserCountryAndLanguage();
  const isCountryEu = isClusterEuUser(userCountry);

  const log = logService();

  try {
    const response = await Api.get({
      url: `${getServiceDomain(isCountryEu)}${VENDORS_GROUP_SERVICE_BASE_URL}/groups/roots`,
      config: {
        params: {pageSize: 10000},
      }
    });

    return response?.data;
  } catch (error) {
    const responseMessage = error?.response?.data?.message ?? error?.response?.data?.messages?.[0];
    log.error(
      `group-service.getAllRoots: Error - ${error.message}.${
        responseMessage ? ` Message: ${responseMessage}` : ''
      }`,
      error
    );
    throw error;
  }
};
