import { Grid } from '@hexa-ui/components';
import { createGenerateClassName, StylesProvider } from '@material-ui/core';
import { GlobalGroupProvider } from 'context/GlobalGroupContext/GlobalGroupContext';
import { UserProvider } from 'context/UserContext/UserContext';
import OptimizelyManager from 'provider/OptimizelyProvider/OptimizelyProvider';
import { IntlProvider } from './i18n';
import { EnvConfig, EnvProvider } from './provider/EnvProvider';
import Router from './Router';

export default function App(props: EnvConfig) {
  const generateClassName = createGenerateClassName({
    productionPrefix: 'bees-hub-vendors-group-mfe',
    seed: 'bees-hub-vendors-group-mfe'
  });

  return (
    <EnvProvider env={props}>
      <StylesProvider generateClassName={generateClassName}>
        <IntlProvider>
          <Grid.Container
            style={{
              rowGap: '1rem',
              display: 'block'
            }}
            type="fluid"
            sidebar
          >
            <OptimizelyManager>
              <UserProvider>
                <GlobalGroupProvider>
                  <Router />
                </GlobalGroupProvider>
              </UserProvider>
            </OptimizelyManager>
          </Grid.Container>
        </IntlProvider>
      </StylesProvider>
    </EnvProvider>
  );
}
