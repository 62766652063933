import { styled } from '@hexa-ui/theme';

const Container = styled('div', {
  display: 'flex',
  justifyContent: 'center'
});

const Content = styled('div', {
  maxWidth: '1090px'
});

const Wrapper = styled('div', {
  marginTop: '30px'
});

const Styled = {
  Container,
  Content,
  Wrapper
};


export default Styled;
