import { Card as HexaCard, Paragraph as HexaParagraph } from '@hexa-ui/components';
import { styled } from '@hexa-ui/theme';

const Card = styled(HexaCard, {
  padding: '$6'
});

const CardHeader = styled('div', {
  marginBottom: '$8'
});

const Paragraph = styled(HexaParagraph, {
  marginTop: '$2'
});

const Styled = {
  Card,
  CardHeader,
  Paragraph
};

export default Styled;
