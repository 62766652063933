import { useDecision } from '@optimizely/react-sdk';
import { ADMIN_PORTAL_SHARED_BFF } from 'config/constants';
import { featureToggles } from 'config/featureToggles';
import { Group } from 'domain/Group';
import { useCallback, useMemo } from 'react';
import { Cluster, Country, UseCountryClusterReturn } from './useCountryCluster.types';

const STATIC_URL = ADMIN_PORTAL_SHARED_BFF;

export default function useCountryCluster(): UseCountryClusterReturn {
  const [decision, isReady] = useDecision(featureToggles.COMPANY_VENDOR_GROUPS_CLUSTER_COUNTRIES);

  const handleIncludeIcon = (country: Country) => {
    return { ...country, icon: `${STATIC_URL}/${country.code}.svg` };
  };

  const countries: Country[] = useMemo(() => {
    const raw: any = decision.variables?.countries;
    return raw?.data ? (raw?.data as Country[]).map(handleIncludeIcon) : [];
  }, [decision]);

  const getAllCountriesByCluster = useCallback(
    (cluster: Cluster, groups: Group[]) =>
      countries
        .filter((country) => country.cluster === cluster)
        .map((countryCluster) => {
          const isDisabled = !!groups.find((group) => group.country === countryCluster.code);
          return { ...countryCluster, isDisabled };
        }),
    [countries]
  );

  const getClusterByCountryCode = useCallback(
    (countryCode: string) => {
      const result = countries.find((country) => country.code === countryCode);
      return result ? result.cluster : null;
    },
    [countries]
  );

  const getCountryByCode = useCallback(
    (code: string) => {
      return countries.find((country) => country.code === code);
    },
    [countries]
  );

  const getCountriesByUserCountry = useCallback(
    (userCountryCode: string, groups: Group[]) => {
      const userCountryCluster = getClusterByCountryCode(userCountryCode);
      return userCountryCluster ? getAllCountriesByCluster(userCountryCluster, groups) : [];
    },
    [getClusterByCountryCode, getAllCountriesByCluster]
  );

  return {
    getAllCountriesByCluster,
    getClusterByCountryCode,
    getCountriesByUserCountry,
    getCountryByCode,
    countries,
    isLoading: !isReady
  };
}
