import { MODELS_OPERATION } from 'config/constants';
import useGlobalGroupContext from 'context/GlobalGroupContext/hooks/useGlobalGroupContext';
import { useStepperContext } from 'context/StepperContext/StepperContext';
import { Vendor } from 'domain/Vendor';
import useCountryCluster from 'hooks/useCountryCluster/useCountryCluster';
import { Cluster } from 'hooks/useCountryCluster/useCountryCluster.types';
import { createContext, useCallback, useEffect, useMemo, useReducer } from 'react';
import { isNilOrEmpty, matchEmail } from 'utils/utils';
import {
  AddUser,
  FieldProps,
  ICreateContext,
  ICreateContextData,
  UpdateActions
} from './CreateContext.d';

const CreateContext = createContext({} as ICreateContext);

function reducer(state: any, action: UpdateActions): ICreateContextData {
  return { ...state, ...action.payload };
}

export const getInitialFieldState = (required = true) => ({
  hasError: false,
  isDirty: false,
  required
});

export const initialState: ICreateContextData = {
  group: {
    name: '',
    enabled: true,
    parentGroupId: '',
    operationModel: MODELS_OPERATION.DECENTRALIZED,
    users: [],
    vendors: []
  },
  fields: {
    name: getInitialFieldState(),
    enabled: getInitialFieldState(),
    parentGroupId: getInitialFieldState(),
    operationModel: getInitialFieldState(),
    vendors: getInitialFieldState(),
    users: getInitialFieldState(false)
  },
  hasError: false
};

function CreateProvider({ children }: { children: React.ReactNode }) {
  const { data } = useGlobalGroupContext();
  const { countries } = useCountryCluster();
  const { currentStep } = useStepperContext();
  const [state, dispatch] = useReducer(reducer, initialState);

  const onValidateName = (name: string) => {
    const VALID_CHAR_REGEX = /^[a-zA-Z0-9 ]+$/;

    let isValid = null;

    if (VALID_CHAR_REGEX.test(name) && name.trim()) {
      isValid = true;
    } else {
      isValid = false;
    }

    return isValid;
  };

  const onValidateEmail = (email: string) => {
    let isValid = null;

    if (!email || !matchEmail(email)) {
      isValid = false;
    } else {
      isValid = true;
    }

    return isValid;
  };

  const onUpdateField = useCallback(
    (fieldName: string, value: Partial<FieldProps>) => {
      dispatch({
        payload: {
          fields: {
            ...state.fields,
            [fieldName]: {
              ...state.fields[fieldName],
              ...value
            }
          }
        }
      });
    },
    [state]
  );

  const onUpdateName = useCallback(
    (name: string) => {
      const isValid = onValidateName(name);

      onUpdateField('name', { hasError: !isValid, isDirty: true, groupError: false });

      dispatch({ payload: { group: { ...state.group, name } } });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [state]
  );

  const onUpdateStatus = useCallback(
    (enabled: boolean) => {
      onUpdateField('enabled', { hasError: false, isDirty: true, groupError: false });

      dispatch({ payload: { group: { ...state.group, enabled } } });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [state]
  );

  const isEmailValidInClusterEU = useCallback(
    ({
      hasUserFromServiceCountry,
      userLoggedCountry
    }: {
      userLoggedCountry: string;
      hasUserFromServiceCountry: string;
    }) => {
      const clusterEU = countries.filter((country) => country.cluster === Cluster.EU).map((country) => {return country.code });

      const isClusterEu = clusterEU.includes(userLoggedCountry);
      const isUserFromServiceInClusterEu = clusterEU.includes(hasUserFromServiceCountry);

      if (isClusterEu) {
        return isClusterEu && isUserFromServiceInClusterEu;
      }

      return !clusterEU.includes(hasUserFromServiceCountry);
    },
    []
  );

  const onUpdateUser = useCallback(
    (user: AddUser) => {
      const isValid = onValidateEmail(user.email);

      onUpdateField('users', { hasError: !isValid, isDirty: true, groupError: false });

      if (isValid) {
        const hasUser = state.group.users.find((prevUser) => prevUser.email === user.email);
        dispatch({
          payload: {
            group: {
              ...state.group,
              users: hasUser
                ? state.group.users.map((prevUser) => {
                    if (prevUser.email === user.email) {
                      return {
                        ...prevUser,
                        ...user
                      };
                    }
                    return prevUser;
                  })
                : [...state.group.users, user]
            }
          }
        });
      }

      return isValid;
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [state]
  );

  const onRemoveUser = useCallback(
    (email: string) => {
      onUpdateField('users', { isDirty: true, groupError: false });

      dispatch({
        payload: {
          group: {
            ...state.group,
            users: state.group.users.filter((prevUser) => prevUser.email !== email)
          }
        }
      });
    },
    [state]
  );

  const onUpdateVendors = useCallback(
    (vendors: Vendor[]) => {
      dispatch({
        payload: {
          group: {
            ...state.group,
            vendors
          }
        }
      });
    },
    [state]
  );

  const onUpdateOperationModel = useCallback(
    (operationModel) => {
      dispatch({
        payload: {
          group: {
            ...state.group,
            operationModel
          }
        }
      });
    },
    [state]
  );

  useEffect(() => {
    dispatch({ payload: { group: { ...state.group, parentGroupId: data?.id } } });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data?.id]);

  useEffect(() => {
    if (currentStep === 0) {
      onUpdateField('vendors', { required: false });
    }
    if (currentStep === 1) {
      onUpdateField('vendors', { required: true });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentStep]);

  const contextValue: ICreateContext = useMemo(() => {
    return {
      updateName: onUpdateName,
      updateStatus: onUpdateStatus,
      updateVendors: onUpdateVendors,
      updateOperationModel: onUpdateOperationModel,
      updateField: onUpdateField,
      updateUser: onUpdateUser,
      removeUser: onRemoveUser,
      isEmailValidInClusterEU,
      dispatch,
      group: state.group,
      fields: state.fields,
      hasError: Object.entries(state.fields).some(
        ([key, value]) => value.hasError || (value.required && isNilOrEmpty(state.group[key]))
      )
    };
  }, [
    onUpdateName,
    onUpdateStatus,
    onUpdateVendors,
    onUpdateOperationModel,
    onUpdateField,
    onUpdateUser,
    onRemoveUser,
    state.fields,
    state.group,
    isEmailValidInClusterEU
  ]);

  return <CreateContext.Provider value={contextValue}>{children}</CreateContext.Provider>;
}

export { CreateContext, CreateProvider };
