import { MODELS_OPERATION } from 'config/constants';
import { Group } from 'domain/Group';
import { Vendor } from 'domain/Vendor';

export function removeSlashFromEnd(url: string) {
  return url.endsWith('/') ? url.slice(0, -1) : url;
}

export function matchEmail(email: string) {
  if (email.indexOf('@') === -1) {
    return false;
  }

  const partes = email.split('@');
  const dominio = partes[1];

  if (dominio.indexOf('.') === -1) {
    return false;
  }

  return true;
}

export const typeOfModelsOperation = (vendors: Vendor[]) => {
  return vendors[0].assigned ? MODELS_OPERATION.CENTRALIZED : MODELS_OPERATION.DECENTRALIZED;
};

export function sortChildGroupsByName(obj: Group) {
  obj.childGroups.sort((a, b) => {
    const nameA = a.name.toUpperCase();
    const nameB = b.name.toUpperCase();
    if (nameA < nameB) {
      return -1;
    }
    if (nameA > nameB) {
      return 1;
    }
    return 0;
  });

  return obj;
}

export function isNilOrEmpty(value: any): boolean {
  if (value === null || value === undefined) {
    return true;
  }
  if (typeof value === 'string') {
    return value.trim().length === 0;
  }
  if (Array.isArray(value)) {
    return value.length === 0;
  }
  if (typeof value === 'object') {
    return Object.keys(value).length === 0;
  }
  return false;
}

export function addAssignedToVendors(type: MODELS_OPERATION, vendors) {
  let vendorsToAdd: Vendor[] = [];

  if (type === MODELS_OPERATION.CENTRALIZED) {
    vendorsToAdd = vendors.map((vendor) => ({ ...vendor, assigned: true }));
  } else {
    vendorsToAdd = vendors.map((vendor) => ({ ...vendor, assigned: false }));
  }

  return vendorsToAdd;
}
