import { CreateData, UpdateData } from 'context/CreateContext/CreateContext.d';
import { GroupToCreate } from 'domain/Group';

export function formatUserName(userEmail: string, userName?: string) {
  const regex = /new user/i;
  if (!regex.test(userName) && userName) return userName;

  const [namePart] = userEmail.split('@');
  const nameParts = namePart.split('.');
  const formattedNameParts = nameParts.map(
    (nameToFormat) => nameToFormat.charAt(0).toUpperCase() + nameToFormat.slice(1)
  );

  return formattedNameParts.join(' ');
}

export const formatCreateData = ({
  data,
  shouldAssignVendors
}: {
  data: CreateData | UpdateData;
  shouldAssignVendors: boolean;
}): GroupToCreate => ({
  name: data.name.trim(),
  enabled: data.enabled,
  icon: '',
  parentGroupId: data.parentGroupId,
  users: data.users.map((user) => ({
    id: user.id,
    country: user.country
  })),
  vendors: data.vendors.map((vendor) => ({
    assigned: shouldAssignVendors,
    id: vendor.id,
    name: vendor.name
  }))
});
