import { Radio } from '@hexa-ui/components';
import { styled } from '@hexa-ui/theme';

const CustomCard = styled('div', {
  width: '335px',
  height: '72px',
  borderRadius: '8px !important',
  border: '1px solid #1414141f',
  padding: '12px 16px',
  variants: {
    type: {
      countryCreated: {
        backgroundColor: '#14141414'
      },
      countryNotCreated: {
        cursor: 'pointer'
      }
    },
    isChecked: {
      true: {
        outline: '4px solid #0F7FFA',
        border: '1px solid transparent'
      },
      false: {
        outline: 'unset'
      }
    },
    isFullWidth: {
      true: {
        width: '100%'
      }
    },
    hasOutlineBorder: {
      false: { outline: 'unset' }
    }
  }
});

const ContentCard = styled('div', {
  display: 'flex',
  gap: '12px',
  div: {
    padding: '0px !important',
    height: '48px !important'
  }
});

const RadioButton = styled(Radio.Root, {
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column'
});

const Styled = {
  CustomCard,
  ContentCard,
  RadioButton
};

export default Styled;
