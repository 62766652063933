import { Tooltip } from '@hexa-ui/components';
import Styled from './ConditionalTooltip.styles';

const ConditionalTooltip = ({
  showTooltip,
  text,
  children
}: {
  showTooltip: boolean;
  text: string;
  children: any;
}) => {
  if (showTooltip) {
    return (
      <Styled.Container>
        <Tooltip placement="right" text={text} data-testid="tooltip">
          {children}
        </Tooltip>
      </Styled.Container>
    );
  }

  return children;
};

export default ConditionalTooltip;
