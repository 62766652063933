import { useAppHeader } from 'admin-portal-shared-services';
import { BASE_URL } from 'config/constants';
import useVendorsRoutes from 'hooks/useVendorsRoutes/useVendorsRoutes';
import { IRoute } from 'hooks/useVendorsRoutes/useVendorsRoutes.d';
import { useEffect } from 'react';
import { Outlet, matchPath } from 'react-router-dom';
import { getGroupById } from 'services/group-service/group-service';
import { removeSlashFromEnd } from 'utils/utils';

function HeaderService() {
  const [, setAppHeaderConfig] = useAppHeader();
  const { vendorsRoutes, activeRoute } = useVendorsRoutes();

  async function getPageTitle(path: string): Promise<string> {
    const route = vendorsRoutes.find((r) => r.path === path);
    let pageTitle = route?.title;

    const rootPath = matchPath(`${BASE_URL}/root/:rootId`, path);
    const groupId = rootPath?.params.rootId;
    if (groupId) {
      const group = await getGroupById(groupId);
      pageTitle = group?.name || route.title;
    }

    return pageTitle;
  }

  function getAllRoutes(path: string) {
    const pathArray = path.split('/').filter((part) => part !== '');
    const transformedArray = pathArray.reduce((acc, curr, index) => {
      if (index === 0) {
        acc.push(`/${curr}`);
      } else {
        const prev = acc[index - 1];
        const transformed = `${prev}/${curr}`;
        acc.push(transformed);
      }

      return acc;
    }, []);
    return transformedArray;
  }

  async function getBreadcrumbItems(path: string) {
    const routes = getAllRoutes(path);
    const breadcrumbItems = await Promise.all(
      routes.map(async (route) => {
        return {
          label: await getPageTitle(route),
          path: route,
          isCurrentPage: route === path
        };
      })
    );

    const filteredBreadcrumbItems = breadcrumbItems.filter((item) => item.label !== undefined);
    return filteredBreadcrumbItems;
  }

  async function configHeaderService(currentRoute: IRoute): Promise<void> {
    const url = currentRoute?.path ? removeSlashFromEnd(currentRoute.path) : '';

    if (currentRoute.hideHeader) {
      return setAppHeaderConfig({
        pageTitle: undefined,
        breadcrumbConfig: undefined
      });
    }

    const breadcrumbItems = currentRoute.showBreadcrumb ? await getBreadcrumbItems(url) : [];
    setAppHeaderConfig({
      pageTitle: currentRoute?.title,
      breadcrumbConfig: {
        homePath: '/',
        items: breadcrumbItems
      }
    });

    return Promise.resolve();
  }

  useEffect(() => {
    configHeaderService(activeRoute);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeRoute]);

  return <Outlet />;
}

export default HeaderService;
