import { TypeToast, useAuthenticationService, useToast } from 'admin-portal-shared-services';
import ButtonsActions from 'components/ButtonsActions/ButtonsActions';
import CardContainer from 'components/CardContainer/CardContainer';
import PageLoading from 'components/PageLoading/PageLoading';
import PermissionCard from 'components/PermissionCard/PermissionCard';
import { BASE_URL } from 'config/constants';
import useCreateContext from 'context/CreateContext/hooks/useCreateContext';
import useCountryCluster from 'hooks/useCountryCluster/useCountryCluster';
import { Country } from 'hooks/useCountryCluster/useCountryCluster.types';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import { createRoot } from 'services/group-service/group-service';
import useGetAllGroups from 'services/group-service/hook/useGetAllGroups';
import CountryCardGroup from '../../components/CountryCardGroup/CountryCardGroup';
import Styled from './PrimaryCluster.styles';

function PrimaryCluster() {
  const [selectedCountry, setSelectedCountry] = useState<Country>(null);
  const [countries, setCountries] = useState<Country[]>([]);
  const [submitIsLoading, setSubmitIsLoading] = useState<boolean>(false);
  const { allGroups: allRoots, isLoading: isAllRootsLoading } = useGetAllGroups(true);
  const { allGroups, isLoading: isFetchLoading } = useGetAllGroups();
  const { getCountriesByUserCountry, isLoading: isToggleLoading } = useCountryCluster();
  const { formatMessage } = useIntl();
  const navigate = useNavigate();
  const { group, fields } = useCreateContext();
  const someUserIsLoading = group.users.some((user) => user.isLoading);
  const usersField = fields.users;

  const authentication = useAuthenticationService();
  const { user_country: userCountry } = authentication.getUserCountryAndLanguage();
  const userId = authentication.getUserId();

  const isNextDisabled = useMemo(() => !selectedCountry, [selectedCountry]);

  const toastService = useToast();
  
  const onNext = useCallback(async (users) => {
    try {
      setSubmitIsLoading(true);
      let userList = [];

      if(Array.isArray(users) && users.length > 0)
        userList = userList.concat(users?.map(({id, country}) => ({id, country})));

      const alreadyHasEmail = userList?.some((user) => user.id === userId);
      if (!alreadyHasEmail) {
        userList = userList.concat([{ id: userId, country: userCountry }]);
      }

      const rootId = await createRoot(selectedCountry, userList);
      navigate(`${BASE_URL}/root/${rootId}`);
      toastService.notify({
        type: TypeToast.SUCCESS,
        message: formatMessage({ id: `PrimaryCluster.SuccessMessage` })
      });
      setSubmitIsLoading(false);
    } catch (error) {
      setSubmitIsLoading(false);
    }
  }, [navigate, selectedCountry, userId, userCountry, toastService, formatMessage]);

  const onGoBack = () => {
    navigate(BASE_URL);
  };

  const onSelectCountry = (country: Country) => {
    setSelectedCountry(country);
  };

  useEffect(() => {
    if (!isFetchLoading && !isAllRootsLoading) {
      const countriesByUserCountry = getCountriesByUserCountry(userCountry, allGroups);
      const newCountries: Country[] = countriesByUserCountry.map((country) => ({
        ...country,
        isDisabled: allRoots.some((root) => root.country === country.code)
      }));

      setCountries(newCountries);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allGroups, allRoots, isAllRootsLoading, isFetchLoading]);

  return isFetchLoading || isAllRootsLoading || isToggleLoading ? (
    <PageLoading />
  ) : (
    <Styled.Container>
      <Styled.Content>
        <CardContainer
          title={formatMessage({ id: 'PrimaryCluster.Title' })}
          subtitle={formatMessage({ id: 'PrimaryCluster.Subtitle' })}
        >
          <CountryCardGroup countries={countries} onChangeCountry={onSelectCountry} />
        </CardContainer>


        <Styled.Wrapper>
          <PermissionCard /> 
        </Styled.Wrapper>
        
        <ButtonsActions
          onCancel={onGoBack}
          onConfirm={() => onNext(group?.users)}
          confirmText={formatMessage({ id: 'PrimaryCluster.Confirm' })}
          cancelText={formatMessage({ id: 'PrimaryCluster.Cancel' })}
          confirmIsDisabled={isNextDisabled || submitIsLoading || someUserIsLoading || usersField.hasError}
          confirmIsLoading={submitIsLoading}
        />
      </Styled.Content>
    </Styled.Container>
  );
}

export default PrimaryCluster;
