import { Env } from 'Models/Env';

export const BASE_URL = '/company-management/vendors-group';

export const ADMIN_PORTAL_SHARED_BFF =
  'https://one-sit.bees-platform.dev/api/v1/admin-portal-shared-bff/static';

const SERVICES_DOMAIN_US = {
  [Env.Prod]: 'https://services.bees-platform.com',
  [Env.Uat]: 'https://services-uat.bees-platform.dev',
  [Env.Sit]: 'https://services-sit.bees-platform.dev'
};

const SERVICES_DOMAIN_EU = {
  [Env.Prod]: 'https://services.eu.mybees-platform.com',
  [Env.Uat]: 'https://services.eu.uat.restricted.mybees-platform.dev',
  [Env.Sit]: 'https://services-sit.bees-platform.dev'
};

export function getServiceDomain(isEu = false) {
  const env = JSON.parse(localStorage.getItem('portal-config'))?.ENV;

  if (isEu) {
    return SERVICES_DOMAIN_EU[env];
  }

  return SERVICES_DOMAIN_US[env];
}

export const IDENTITY_SERVICE_BASE_URL = '/identity-service';

export const VENDORS_GROUP_SERVICE_BASE_URL = '/bees-force-vendor-groups-service';

export const STEP_ROUTES = ['/details', '/vendors'];

export const PAGE_SIZE = {
  WITH_25: 25,
  WITH_50: 50,
  WITH_100: 100
};

export const APP_NAMES = [
  'AdminPortal',
  'B2b',
  'Cmsadmin',
  'Deliveradmin',
  'Force',
  'Oncustomer',
  'Social',
  'Supplier'
];

export enum MODELS_OPERATION {
  CENTRALIZED = 'CENTRALIZED',
  DECENTRALIZED = 'DECENTRALIZED'
}

export const ADMIN_PORTAL_VENDOR_COMPANY_MANAGEMENT_WRITE =
  'AdminPortalVendor.CompanyManagement.Write';
export const ADMIN_PORTAL_VENDOR_GROUP_WRITE = 'AdminPortalVendor.Group.Write';

export const AUTHORIZATION_SCOPES = [
  ADMIN_PORTAL_VENDOR_COMPANY_MANAGEMENT_WRITE,
  ADMIN_PORTAL_VENDOR_GROUP_WRITE
];
