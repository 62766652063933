import { useUserMetadata } from 'admin-portal-shared-services';
import PageError from 'components/PageError/PageError';
import PageLoading from 'components/PageLoading/PageLoading';
import { BASE_URL } from 'config/constants';
import { useHeaderHeight } from 'hooks/useHeaderHeight/useHeaderHeight';
import { usePermissions } from 'hooks/usePermissions/usePermissions';
import PrimaryCluster from 'pages/PrimaryCluster/PrimaryCluster';
import { lazy, Suspense } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Styled from 'Router.styles';
import HeaderService from 'services/header/HeaderService';

const HomePage = lazy(() => import('./pages/HomePage/HomePage'));

const CreatePage = lazy(() => import('./pages/CreatePage/CreatePage'));
const GroupDetails = lazy(() => import('./pages/CreateGroupDetailsPage/CreateGroupDetailsPage'));
const GroupVendors = lazy(
  () => import('./pages/CreateGroupAddVendorsPage/CreateGroupAddVendorsPage')
);
const RootPage = lazy(() => import('./pages/RootPage/RootPage'));
const GroupPage = lazy(() => import('./pages/GroupPage/GroupPage'));
const EditGroupPage = lazy(() => import('./pages/EditGroupPage/EditGroupPage'));
const SubgroupDetails = lazy(
  () => import('./pages/CreateSubgroupDetailsPage/CreateSubgroupDetailsPage')
);
const SubgroupVendors = lazy(
  () => import('./pages/CreateSubgroupAddVendorsPage/CreateSubgroupAddVendorsPage')
);
const SubGroupPage = lazy(() => import('./pages/SubGroupPage/SubGroupPage'));

const EditSubgroupPage = lazy(() => import('./pages/EditSubgroupPage/EditSubgroupPage'));

const EditPrimaryClusterPage = lazy(
  () => import('./pages/EditPrimaryClusterPage/EditPrimaryClusterPage')
);

const CreatePrimaryCluster = lazy(
  () => import('./pages/CreatePrimaryCluster/CreatePrimaryClusterPage')
);

export function RouterContent() {
  const { headerHeight } = useHeaderHeight();

  const { hasPermission } = usePermissions();

  const { data: userMetadata } = useUserMetadata();

  if (!hasPermission('admin'))
    return <PageError redirectTo="/" errorType="noPermission" />;

  if (!userMetadata) return <PageLoading />;

  return (
    <Styled.AppContainer css={{ $$headerHeight: headerHeight }}>
      <Suspense fallback={<PageLoading />}>
        <Routes>
          <Route path={BASE_URL}>
            <Route element={<HeaderService />}>
              <Route index element={<HomePage />} />
              <Route path="create" element={<CreatePrimaryCluster />}>
                <Route path="primary-cluster" element={<PrimaryCluster />} />
              </Route>
              <Route path="root/:rootId">
                <Route index element={<RootPage />} />
                <Route path="edit-primary-cluster" element={<EditPrimaryClusterPage />} />
                <Route path="create-group" element={<CreatePage />}>
                  <Route path="details" element={<GroupDetails />} />
                  <Route path="vendors" element={<GroupVendors />} />
                </Route>
                <Route path="group/:groupId">
                  <Route index element={<GroupPage />} />
                  <Route path="edit-group" element={<EditGroupPage />} />
                  <Route path="create-subgroup" element={<CreatePage />}>
                    <Route path="details" element={<SubgroupDetails />} />
                    <Route path="vendors" element={<SubgroupVendors />} />
                  </Route>
                  <Route path="subgroup/:subGroupId">
                    <Route index element={<SubGroupPage />} />
                    <Route path="edit-group" element={<EditSubgroupPage />} />
                  </Route>
                </Route>
              </Route>
            </Route>
          </Route>
        </Routes>
      </Suspense>
    </Styled.AppContainer>
  );
}

function Router() {
  return (
    <BrowserRouter>
      <RouterContent />
    </BrowserRouter>
  );
}

export default Router;
