import { styled } from '@hexa-ui/theme';

const ActionContainer = styled('div', {
  display: 'flex',
  justifyContent: 'end',
  marginTop: '40px',
  gap: '12px'
});

const Styled = {
  ActionContainer
};

export default Styled;
