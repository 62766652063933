import { Heading } from '@hexa-ui/components';
import { CardContainerProps } from './CardContainer.d';
import Styled from './CardContainer.styles';

function CardContainer({ title, subtitle, children, titleSize = 'H2' }: CardContainerProps) {
  return (
    <Styled.Card border="large" elevated="small">
      <Styled.CardHeader>
        <Heading size={titleSize}>{title}</Heading>
        <Styled.Paragraph colortype="secondary">{subtitle}</Styled.Paragraph>
      </Styled.CardHeader>
      {children}
    </Styled.Card>
  );
}

export default CardContainer;
