import { createContext, useCallback, useContext, useMemo, useReducer } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  IStepperActions,
  IStepperContext,
  IStepperContextData,
  UpdateActions
} from './StepperContext.d';

export const ROUTES = ['details', 'vendors'];

const StepperContext = createContext({} as IStepperContext);

function reducer(state: any, action: UpdateActions): IStepperContextData {
  return { ...state, ...action.payload };
}

export const initialState: IStepperContextData = {
  currentStep: 0,
  actions: {
    next: {
      label: 'Steps.Actions.Next',
      disabled: false
    },
    back: {
      label: 'Steps.Actions.GoBack',
      disabled: false
    }
  }
};

function StepperProvider({ children }: { children: React.ReactNode }) {
  const location = useLocation();
  const [state, dispatch] = useReducer(reducer, initialState);
  const navigate = useNavigate();

  const updateActions = useCallback(
    (actions: IStepperActions) => {
      dispatch({
        payload: {
          actions: {
            next: {
              ...state.actions.next,
              ...actions.next,
              label: actions.next?.label || initialState.actions.next.label
            },
            back: {
              ...state.actions.back,
              ...actions.back,
              label: actions.back?.label || initialState.actions.back.label
            }
          }
        }
      });
    },
    [state.actions]
  );

  const nextStep = useCallback(() => {
    const currentUrl = location.pathname;

    const currentStep = ROUTES.findIndex((route) => location.pathname.includes(route));

    const newUrl = currentUrl.replace(
      `group/${ROUTES[currentStep]}`,
      `group/${ROUTES[currentStep + 1]}`
    );

    navigate(newUrl, {
      state: { currentStep: document.location.href }
    });
    dispatch({ payload: { currentStep: currentStep + 1 } });
  }, [location.pathname, navigate]);

  const previousStep = useCallback(() => {
    const currentStep = ROUTES.findIndex((route) => location.pathname.includes(route));

    if (currentStep > 0) {
      const currentUrl = location.pathname;

      const newUrl = currentUrl.replace(
        `group/${ROUTES[currentStep]}`,
        `group/${ROUTES[currentStep - 1]}`
      );

      navigate(newUrl, {
        state: { currentStep: document.location.href }
      });
      dispatch({ payload: { currentStep: currentStep - 1 } });
    }
  }, [location.pathname, navigate]);

  const contextValue: IStepperContext = useMemo(() => {
    return {
      nextStep,
      previousStep,
      updateActions,
      actions: state.actions,
      currentStep: ROUTES.findIndex((route) => location.pathname?.includes(route))
    };
  }, [nextStep, previousStep, updateActions, state.actions, location.pathname]);

  return <StepperContext.Provider value={contextValue}>{children}</StepperContext.Provider>;
}

function useStepperContext() {
  return useContext(StepperContext);
}

export { StepperContext, StepperProvider, useStepperContext };
