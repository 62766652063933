/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { useAuthenticationService } from 'admin-portal-shared-services';
import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import { enableMockAdapter } from './mocks/mockAdapter';

export interface IRequest {
  url: string;
  config?: AxiosRequestConfig;
  body?: unknown;
}

export const axiosInstance = axios.create();
enableMockAdapter(axiosInstance);
const authentication = useAuthenticationService();

const { user_country: userCountry } = authentication.getUserCountryAndLanguage();

axiosInstance.defaults.baseURL = '/api';

authentication.enhancedAxios(
  axiosInstance as unknown as Parameters<typeof authentication.enhancedAxios>[0],
  {
    headers: [
      {
        country: userCountry ?? '',
        timezone: 'UTC'
      }
    ]
  }
);

const api = {
  get: ({ url, config }: IRequest): Promise<AxiosResponse> => axiosInstance.get(url, config),

  post: ({ url, body, config }: IRequest): Promise<AxiosResponse> =>
    axiosInstance.post(url, body, config),

  delete: ({ url, config }: IRequest): Promise<AxiosResponse> => axiosInstance.delete(url, config),

  put: ({ url, body, config }: IRequest): Promise<AxiosResponse> =>
    axiosInstance.put(url, body, config),

  patch: ({ url, body, config }: IRequest): Promise<AxiosResponse> =>
    axiosInstance.patch(url, body, config)
};

export default api;
